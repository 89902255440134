@import "vars.scss";
// bower:scss
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower
@import "mobile.scss";
@import "tablet.scss";
@import "desktop.scss";
@import "desktop_lg.scss";
@import 'navbar';
@import 'dlaBudujacych.scss';
@import 'cookie.scss';
@import 'lang.scss';

.rus {
  font-family: 'lato', sans-serif !important;

  h1,h2,h3,h4,h5,h6,p,a,div,span {
    font-family: 'lato', sans-serif !important;
  }
}

body {
  margin: 0 auto;
}

p {
  margin: 0;
}

figure {
  position: relative;
}

.grey {
  color: $grey;
}

hr.grey {
  border-color: $grey;
  color: $grey;
}

.header {
  overflow: hidden;
}

.greyBackg {
  background-color: $blueGrey;
  text-align: center;
  overflow: hidden;
  p {
    padding-bottom: 40px;
  }

}

.white {
  color: $white;
  hr {
    border-color: $white;
    color: $white;
  }

  p {
    color: $white;
  }
}

#map {
  position: relative;
  z-index: 0;
  top: 0;
  left: 0;

  .sectionTopMargin.benefits.inNumbers {
    background: transparent;
    position: absolute;
    left: 15px;
    top: 15px;
    padding: 19px 8.33%;
  }

}

#contain, .sectionTopMargin {
  position: relative;
  z-index: 1;
  margin: 0 auto;
}

#mapsection .sectionTopMargin {
  top: -360px;
}

figure div {
  display: inline-block;
}

.footer {

  @media screen and (min-width: 992px) {
    text-align: left;
  }
}

.logos-container {
  list-style: none;
  padding-left: 0;
  width: 100%;
  float: left;

  li {
    position: relative;
    float: left;
    width: 50%;
    height: 120px;
    text-align: center;

    @media screen and (min-width: $screen-sm-min) {
      width: 33%;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      max-width: 100%;
    }
  }
}

.filesList {
  display: inline;
  float: left;
  width: 100%;
  overflow: hidden;

  li {
    position: relative;
    width: 50%;
    float: left;
    height: 150px;
    @media screen and (min-width: $screen-sm-min) {
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  a {
    cursor: pointer;
  }
  a img {
    display: inline;
  }
}

.benefits, .gradientBackg, #map {
  margin: 0 -15px;
}



.modal-download {
  .modal-content {
    background-image: linear-gradient(180deg, #D72323 0%, #A01D1D 100%);
    box-shadow: 0 11px 24px 0 rgba(0,0,0,0.50);
    border-radius: 6px;
    padding: 30px 15px;
    border: 0;

    @media screen and (min-width: 768px) {
      padding: 30px 65px;
    }
  }

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .modal-header,
  .modal-footer {
    border: 0;
  }

  .modal-house-bg {
    position: absolute;
    right: 15px;
    left: 15px;
    top: 50%;
    transform: translate(0, -50%);
    background: url('../images/domek.svg') no-repeat;
    background-size: cover;
    height: 207px;
    max-width: 100%;

    @media screen and (min-width: 768px) {
      right: 30px;
      left: auto;
      width: 306px;
    }
  }

  .modal-header {
    position: relative;
  }

  .modal-title {
    font-family: $museo-sans;
    font-weight: 300;
    font-style: italic;
    color: #FFFFFF;
    letter-spacing: 0;
    font-size: 24px;
    line-height: 30px;

    @media screen and (min-width: 768px) {
      font-size: 42px;
      line-height: 42px;
    }
  }

  .modal-body {
    position: relative;
    font-family: $museo-slab;
    color: #FFFFFF;
    letter-spacing: 0;

    @media screen and (min-width: 768px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .modal-footer {
    position: relative;
    text-align: left;

    .btn {
      background-image: linear-gradient(180deg, #F2F3F6 0%, #D4D7E1 100%);
      font-size: 16px;
      color: #374653;
    }
  }
}


section {
  max-width: 100%;
  overflow: hidden;
}
