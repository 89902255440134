@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";

$container-tablet: 768px;
$container-sm: $container-tablet;

$icon-font-path: '../fonts/';

$grey: #294155;
$blueGrey: #374653;
$white: #ffffff;
$certificatesBckg: #f2f3f6;

$museo-sans-cond: "museo-sans-condensed";
$museo-sans: "museo-sans";
$museo-slab: "museo-slab";