@import "vars.scss";

@media screen and (min-width: $screen-lg-min) {

  h2 {
    font-size: 100px;
  }

  .benefits{
    img {
      max-width: 285px;
    }
    img + img {
      max-width: 345px;
    }
  }

  .technology {
    float: right;
  }

  .technology.simple div {
    text-align: left;
  }

  .technology.simple.grey {
    text-align: center;
    display: flex;
  }
  .visibleT {
    display: block;
  }
  .benefits {
    background: url("../images/section01_desktop_lg.png") no-repeat center;
    background-size: 100% 100%;
  }

  .about .sectionTopMargin {
    padding-top: 0;
  }


}