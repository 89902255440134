@import "vars.scss";

.cookie {

  display: none;

  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);
  min-height: 63px;
  background: $white;
  font-family: $casualFont;
  font-weight: 700;
  font-size: 11px;
  color: $grey;
  border-radius: 4px;
  padding: 22px;
  opacity: 1;

  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;



  table {
    margin: 0 auto;
  }
  .first-td {
    background: url("../images/image-1.svg") no-repeat left;
    background-size: auto 100%;
    padding-right: 30px;
  }

  .close-button {
    vertical-align: top;

    right: 0;

  }
}