@import "vars.scss";

.dla-wladz {
  background: url("../images/2grey.png");
  background-size: 100%;
}

.light-grey {
  background: $grey no;
  width: calc(100% + 30px);
}

.wiecej-domow {
  max-width: 100%;
}

.technology-transfer {
  p {
    margin-bottom: 24px;
    margin-top: 40px;
    position: relative;
    left: 0;
  }
  ul {
    padding: 0;
    li {
      clear: both;

    div {
      display: inline;
      float: left;
      margin-bottom: 28px;
      padding-right: 19px;
    }
    p {
      display: inline;
      &:last-child{

        margin-bottom: 40px;
      }
    }
  }}
}