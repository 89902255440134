@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";

$icon-font-path: '../fonts/';


$grey: #294155;
$blueGrey: #374653;
$white: #ffffff;
$lightGrey: #f2f3f6;
$certificatesBckg: #f2f3f6;

$headerFont: "museo-sans-condensed";
$sectionFont: "museo-sans";
$casualFont: "museo-slab";

$screen320: 320px;
$screen321: 321px;

