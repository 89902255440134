@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";
@import 'variables';

body {
  margin: auto auto;
}

.btn-izodom {
  color: white;
  background-image: linear-gradient(to bottom, #d72323, #a01d1d);
  padding-left: 20px;
  padding-right: 20px;
  transition: background 0.2s ease-out;

  &:hover, &:focus {
    color: white;
    background-image: linear-gradient(to bottom, #a01d1d, #d72323);
  }
}

.navbar-izodom {
  margin: 0;
  font-family: $museo-slab;
  font-size: 16px;
  border: 0;

  .navbar-nav .open .dropdown-menu > li > a, .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 0;
  }

  .dropdown-lang {
    @media screen and (min-width: $screen-md-min) {
      border: 1px solid rgba(0, 0, 0, 0.15);
      width: 60px;
    }

    padding: 0;

    img {
      display: block;
      width: 27px;
      height: 27px;
      border-radius: 50%;
      margin: auto;
      padding: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .dropdown-menu {
      width: 100%;
    }

    ul {
      padding: 0;
      min-width: 0;
      box-shadow: none;
    }
    li {
      height: 46px;
      padding: 0;
      @media screen and (min-width: $screen-md-min) {
        height: 46px;
      }
    }

    a {
      width: 100%;
      height: 46px;
      position: relative;
      padding: 0;
    }

    > a {
      background-color: #f2f3f6;

      .caret {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translate(0, -50%);

        color: #a01d1d;
        border-top: 9px dashed;
        border-top: 9px solid \9
      ;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
      }

      img {
        margin: 0;
        padding: 0;

        @media screen and (min-width: $screen-md-min) {
          margin-left: -5px;
        }
      }
    }
  }

  .navbar-toggle {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    margin: 0;

    .icon-bar {
      background: black;
      height: 3px;
      width: 25px;
    }

    .icon-bar + .icon-bar {
      margin-top: 6px;
    }
  }

  .navbar-header {
    position: relative;
    @include make-lg-column-offset(1);

    > .btn-izodom {
      position: absolute;
      top: 50%;
      right: 80px;
      transform: translate(0, -50%);
    }
  }

  .navbar-brand {
    height: auto;

    padding-top: 5px;
    padding-bottom: 5px;

    @media screen and (min-width: $screen-md-min) {
      margin-left: 0 !important;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .nav {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .nav > li > a.btn-izodom {
    padding-left: 50px;
    padding-right: 50px;
    font-weight: 700;
    font-size: 16px;
  }

  .nav > li > a {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  li {
    @media screen and (min-width: $screen-md-min) {
      padding-left: 25px;
      padding-right: 25px;
    }

    a.link {
      padding-left: $grid-gutter-width / 2;
      padding-right: $grid-gutter-width / 2;

      @media screen and (min-width: $screen-md-min) {
        padding-left: 0;
        padding-right: 0;
      }

      color: #294155;
      overflow: hidden;

      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        transform: translate(-100%, 0);
        transition: transform 0.2s ease-out;
        background-image: linear-gradient(to bottom, #d72323, #a01d1d);
      }

      &:hover, &:focus {
        background: white;

        &:after {
          transform: translate(0, 0);
        }
      }
    }
  }

  li.active {
    a.link {
      position: relative;

      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 3px;
        background-image: linear-gradient(to bottom, #d72323, #a01d1d);
        transform: translate(0, 0);
      }
    }
  }

  .nav > li:last-child {
    padding-right: 0;
  }
}

@media (max-width: $screen-md-min) {
  .navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
    margin-left: 0 !important;
  }

  .navbar-header {
    float: none;
  }
  .navbar-left, .navbar-right {
    float: none !important;
    margin-left: $grid-gutter-width / -2;
    margin-right: $grid-gutter-width / -2;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-nav {
    float: none !important;
    margin-top: 7.5px;
  }
  .navbar-nav > li {
    float: none;
  }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collapse.in {
    display: block !important;
  }
}

.navbar-izodom {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background: #fff;
  opacity: 1;
  z-index: 1000;
}