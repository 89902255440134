@import "vars.scss";

body {
  color: $white;
}

p {
  font-family: $casualFont;
  font-size: 16px;
  font-weight: 300;
  color: $grey;
}

ul {
  padding-left: 0;
}

.desktop {
  display: none;
}

.mobile {
  display: block;
}

.italic {
  font-style: italic;
  font-family: $sectionFont;
  padding-bottom: 23px;
  text-align: left !important;
  font-size: 20px;
  clear: both;
}

.glyphicon-menu-hamburger {
  font-size: 36px;
  float: right;
  color: $grey;
}

h2 {
  font-size: 40px;
  font-weight: 900;
  line-height: 1;
  font-family: $headerFont;
  margin: 0;
  padding-bottom: 20px;
}

h3 {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  padding-bottom: 15px;
  font-family: $casualFont;
  margin: 0;
}

.header {
  h2 {
    padding-top: 40px;
  }

  img {
    padding-bottom: 35px;
  }
}

.textLeft {
  text-align: left;
}

.benefits {
  background: $white url("../images/section01.png") no-repeat center top;
  padding: 29px 8.33% 0;
  p {
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      height: 1px;
      background: $white;
      width: 65px;
    }
  }
  figure {
    text-align: center;
  }
  img, img + img {
    max-width: 262px;
  }
}

.numbers {
  font-family: $casualFont;
  font-size: 30px;
  font-weight: 300;
  vertical-align: middle;
  padding-right: 58px; //hr horizontal alignment
}

hr {
  width: 63px;
  height: 3px;
  border-color: $white;
  color: $white;
  margin: 9px 0 26px;
}

.sectionHeader {
  font-family: $sectionFont;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.06;
  color: $white;
  vertical-align: middle;
  padding-top: 4px;
}

p.sectionHeader.simple {
  text-align: center;
}

.simpleHeader {
  padding: 30px 0 0;
  text-align: left;
}

.about {
  background: none !important;
  padding: 29px 8.33% 0;
  p.grey {
    text-align: left;
    &:after {
      background: $grey;
    }
  }
}

.gradientBackg {
  background-image: linear-gradient(to bottom, #d72323, #a01d1d);
  padding-top: 40px;
  padding-bottom: 44px;
  padding-left: 13.7%;
  padding-right: 13.7%;

}

.technology.grey.simple {
  ul {
    list-style: none;
    text-align: center;
  }
}

.technology.white {
  ul {
    list-style: none;
    padding: 0;
    margin-left: -13.7%;

    p {
      width: 60%;
      margin: 0 auto;
    }
  }
}

.sectionTopMargin {
  padding-top: 29px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50000;
}

.certificates {
  background-color: $certificatesBckg;
  padding-bottom: 48px;
  overflow: hidden;

  a > img {
    max-width: 94px;
    width: 100%;
  }

  p.simpleHeader {
    padding-bottom: 85px;
  }
}

.certificates a > img {
  margin-bottom: 56px;
}

.filesList {
  list-style: none;
  display: block;
  clear: both;
  li {
    width: 48%;
    float: left;
  }
  a img {
    display: inline;
  }
}

.collaboration {
  background: url("../images/collaboration_bgx2.png") no-repeat center top;
  background-size: 100% 100%;
  padding-top: 58px;
  padding-bottom: 34px;
  overflow: hidden;
  margin: 0;

  p.simple.italic.white {
    padding-bottom: 25px;
    display: inline;
  }
}

#map {
  height: 380px;
  .inNumbers {
    &:first-child {
      margin-top: 0;
      padding: 75px 0 0 0;
    }
    p {
      padding: 0;
      &:after {
        background: $grey;
      }
    }
  }

  iframe {
    width: 100%;
    height: 380px;
    border: none;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

  }
}

.footer p {
  font-size: 10px;
  font-weight: 300;
  line-height: 1.7;
  padding: 25px 0 58px;
  color: $white;
  opacity: 0.49;
}

.visibleT {
  display: none;
}

.technology {
  text-align: center;

  p {
    padding: 0 0 50px 0;
  }

  img {
    padding-bottom: 18px;
  }
}

@media screen and (max-width: $screen-xs-min) {

  .header {
    background: url("../images/header_tablet.png") no-repeat center;
    background-size: auto 100%;
  }

  .benefits > p {
    margin-bottom: 25px;
  }

  .benefits > figure > img {
    max-width: 100%;
  }

  .gradientBackg, .certificates, .collaboration, .benefits {
    overflow: hidden;
  }

  .technology.simple.inNumbers {
    ul {
      &:first-child {
        img {
          margin-left: 30px;
        }
      }
      padding-left: 0;
      padding-top: 52px;
    }
    .italic {
      padding-left: 8.33%;
    }
  }

  p.technology.italic {
    padding-top: 22px;
  }

  .contentImage {
    max-width: 100%;
  }

  .numbers {
    padding-right: 32px;
  }

  .marocco-styles {
    padding: 0 29px 40px;
  }

  .certificates {
    .simple.logos img {
      padding-right: 10px;
    }

    p.simpleHeader {
      padding: 30px 0 40px;
    }

    p.italic.grey.simple {
      padding: 0 0 37px;
    }
  }
}

@media screen and (max-width: 319px) {
  .marocco-styles {
    padding-top: 40px;
  }
}

@media screen and (max-width: 768px) {
  .technology.simple.inNumbers {
    .years-25 {
      margin-right: -35px;
    }
  }
}

