@import "vars.scss";

@media screen and (min-width: $screen-md-min) {
  h2, h2.simple {
    font-size: 72px;
  }

  .footer p{
    padding-left:  85px;
  }
}

@media screen and (min-width: $screen-sm-min) {

  .visibleT {
    display: block;
  }

  .header {
    background: url("../images/header.png") no-repeat center;
  }

  h2 {
    font-size: 90px;
  }

  .benefits {
    background: url("../images/section01_tabletx2.png") no-repeat center bottom;
    background-size: auto 100%;
    padding: 29px 0 0 35px;
    p {
      padding-left: 96px;
    }
    img {
      max-width: 285px;
    }
    img + img {
      max-width: 345px;
    }
  }

  .about {
    padding-top: 29px;
  }

  .technology {
    div {
      text-align: left;
    }
    ul li {
      width: 32%;
      display: inline-block;
      vertical-align: top;
      p {
        text-align: left;
        width: 70%;
      }
    }
  }

  .gradientBackg {
    padding-left: 8.33%;
  }

  .greyBackg {
    padding-top: 90px;
    padding-bottom: 100px;

    p.sectionHeader.simple {
      text-align: left;
      line-height: 1.68
    }
    img.contentImage {
      max-width: 100%;
      margin: 0;
    }
  }

  .italic.simple.grey {
    margin-bottom: 53px;
  }

  .marocco-styles div+div {
      margin-top: 25px;
    }

  .inNumbers {
    position: relative;
    p:after {
      left: -15px;
    }
    &:first-child {
      margin-top: 35px;
      padding: 0;
    }

    ul {
      padding-left: 0;
      text-align: left;
      li {
        width: 32.5%;
        div {
          display: inline-block;
        }
      }
    }
  }

  .inNumbers p:after {
    margin-left: 15px;
  }
  .about.benefits.sectionTopMargin.inNumbers {
    padding: 0;
  }

  .certificates > div {
    margin-bottom: 0;
    .simple.logos {
      margin-left: 0;
    }
  }

  .certificates {
    p.italic.grey.simple, p.simpleHeader {
      margin-left: 0;
      padding-top: 85px;
    }
  }

  .logos {
    text-align: left;
    margin: 0 auto;
  }


  .collaboration {
    background: url("../images/collaboration_bg_tabletx2.png") no-repeat center top;
    padding-top: 72px;
    padding-bottom: 96px;
    overflow: hidden;

    div > img.contentImage {
      display: inline-flex;
      text-align: left;
      max-width: 100%;
      margin: 0;
      padding-right: 8.33%;
    }

    p, h3 {
      display: inline;
    }
  }

  .benefits, .gradientBackg, #map  {
    margin: 0 -15px;
  }
}

@media screen and (min-width: $screen-md-min) {
  .header {
    background: url("../images/header.png") no-repeat center;
  }

  .simple {
    padding-left: 0;
  }

  hr {
    margin-bottom: 39px;
  }

  .benefits {
    background: url("../images/section01_desktop_lg.png") no-repeat center;
    padding-top: 69px;
    width: calc(100% + 30px);

    p {
      padding-left: 20%;
    }
    figure {
      text-align: left;
      img {
        max-width: calc(100% - 30px);
      }
      img + img {
        max-width: calc(83% - 30px);
      }
    }
  }

  .about {
    padding-top: 85px;
    padding-bottom: 85px;
  }

  #producer {
    padding-top: 77px;
  }
  .technology.grey.simple {
    ul li {
      width: 19.5%;
      p {
        padding-right: 35px;
        text-align: left;
      }
    }
    li + li, li + li + li, li + li + li + li {
      padding-left: 35px;
    }
  }

  .technology.simple.grey {
    display: flex;
  }

  #technology .simpleHeader {
    padding-top: 65px;
    padding-bottom: 32px;
  }

  .desktop {
    display: block;
    width: calc(100% + 30px);
  }

  .mobile {
    display: none;
  }

  .gradientBackg {
    float: right;
    padding-left: 13.7%;
  }

  .greyBackg p.sectionHeader.simple {
    text-align: center;
  }

  .about .sectionTopMargin {
    padding-top: 0;
  }

  .sectionTopMargin {
    padding-top: 64px;
  }

  .inNumbers {
    ul {
      padding-left: 0;
      li {
        width: 19.5%;
        p {
          padding: 0;
        }
      }
    }
  }

  .filesList {
    clear: both;
    display: block;
    li {
      width: 49%;
      float: left;
    }
    a img {
      display: inline;
    }
  }

}
