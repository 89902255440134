@import "vars.scss";

@media screen and (min-width: $screen-xs-min) {
  .navbar-brand {
    left: 8.33% !important;
  }
  .visibleT {
    display: block;
  }

  .header {
    background: url("../images/header_tablet.png") no-repeat center;
  }

  h2, h2.simple {
    font-size: 70px;
    padding-bottom: 23px;
  }

  h3 {
    margin-bottom: 51px;
  }

  .benefits {
    background: url("../images/section01_desktop_lg.png") no-repeat center;
    background-size: 100%;
  }

  .sectionHeader {
    line-height: 30px;
  }

  hr {
    margin-left: 0;
  }

  .simple {
    padding: 0;
  }

  .contentImage {
    display: inline;
    img {
      margin-bottom: 40px;
    }
  }

  .technology.white {
    padding-top: 61px;

    ul {
      list-style: none;
      margin: 0;
      li {
        width: 49%;
        display: inline-block;
        vertical-align: top;
        div {
          text-align: left;
        }
        p {
          display: block;
          text-align: left;
        }
      }

      p {
        width: 60%;
      }
    }
  }

  .technology.simple {
    ul {
      padding-left: 0;
    }
    .grey {
      ul {
        padding: 0;
        margin: 0;
        text-align: left;
        li {
          width: 32%;
          display: inline-block;
          vertical-align: top;
          p {
            margin: 0;
            text-align: left !important;
          }
        }
      }
    }
  }

  .technology.white ul p {
    margin: 0;
  }

  .inNumbers > ul {
    margin-top: 54px;
  }
  .simpleHeader {
    padding-top: 41px;
    p {
      padding-top: 60px;
    }

    .visibleT {
      display: none;
    }
  }

  #technology {
    position: relative;
    .simpleHeader:after {
      content: '';
      display: block;
      margin-left: $grid-gutter-width / 2;
      position: absolute;
      left: 0;
      height: 1px;
      background: $grey;
      width: 65px;
    }
  }
  //
  //.simpleHeader {
  //  margin-top: 11px;
  //}

  .about p {
    text-align: center;
  }

  .greyBackg.about {
    padding-top: 50px;

    p.sectionHeader.simple {
      padding-top: 25px;
    }
  }

  .certificates {
    .simple.logos img {
      padding-right: 10px;
    }

    p.simpleHeader {
      padding: 53px 0 40px;
    }
  }

  .filesList {
    clear: both;
    display: inline;
    li {
      width: 25%;
      float: left;
    }
    a img {
      display: inline;
    }
  }

  p.italic.grey.simple {
    padding-bottom: 40px;
  }

  img.contentImage {
    margin: 30px 0;
  }

  .collaboration {
    background: $blueGrey url("../images/collaboration_bg_tablet.png") no-repeat center top;
    background-size: auto 100%;
  }

  #map, #map iframe {
    height: 713px;
  }

  .marocco-styles {
    padding: 50px 75px;
    text-align: left;
    div {
      width: 49%;
      margin: 0;
      display: inline;
      img {
        width: inherit;
      }
    }
    div+div {
      float: right;
      margin-top: 50px;
    }
  }

  .gradientBackg, .certificates, .collaboration, .benefits {
   // margin: 0 auto;
    overflow: hidden;
  }

  #map {
    margin: 0 -15px;
  }

  .logos {
    text-align: left;

    margin: 0 auto;
    li {
      display: inline;
      img{
        max-width: 30%;
      }
    }
  }
}

@media screen and (min-width: $screen321) and (max-width: $screen-xs-max) {

  .technology {
    text-align: center;
    p {
      display: inline-block;
      vertical-align: top;
      text-align: left;
    }

    .italic {
      padding-top: 77px;
    }

    ul p {
      text-align: center;
    }
  }

  .technology div {
    text-align: center;
    p {
      display: block;
      text-align: center;
      width: 60%;
      margin: auto;
    }
  }

  p.sectionHeader.simple {;
    .visibleT {
      display: none;
    }
  }

}

@media screen and (min-width: $screen321) and (max-width: $screen-xs-min) {

  .header {
    background: url("../images/header_tablet.png") no-repeat center;
    background-size: 100% 100%;
  }

  h2, h2.simple {
    font-size: 45px;
  }

  .greyBackg {
    img.contentImage {
      margin-top: 38px;
    }

    p.sectionHeader {
      padding-top: 0;
      text-align: left;
    }
  }

  .benefits {
    background-size: 100%;
  }

  .visibleT {
    display: none;
  }

  hr.visibleT {
    display: block;
  }
}
